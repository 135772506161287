import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NotificationType} from "@/api/requests/notifications/getNotifications";

export const NOTIFICATIONS = 'notifications'

type notificationStateType = {
  unread: {
    items: NotificationType[] | []
    totalCount: number
  }
  read: {
    items: NotificationType[] | []
    totalCount: number
  }
  downloadingReports: string[]
}

const initialState: notificationStateType = {
  unread: {
    items: [],
    totalCount: 0
  },
  read: {
    items: [],
    totalCount: 0
  },
  downloadingReports: [] as string[],
}

const notificationsSlice = createSlice({
  name: NOTIFICATIONS,
  initialState,
  reducers: {
    setReadNotifications: (state, {payload: {totalCount, items}}: PayloadAction<{items: NotificationType[], totalCount: number}>) => {
      state.read.items = items
      state.read.totalCount = totalCount
    },
    setUnreadNotifications: (state, {payload: {totalCount, items}}: PayloadAction<{items: NotificationType[], totalCount: number}>) => {
      state.unread.items = items
      state.unread.totalCount = totalCount
    },
    readUnreadNotification: (state, {payload}: PayloadAction<string>) => {
      const targetItem = state.unread.items.find(item => item.chain.chainId === payload)
      if (targetItem) {
        state.read.items = [{...targetItem, isRead: true}, ...state.read.items]
        state.read.totalCount = state.read.totalCount + 1
        state.unread.items = state.unread.items.filter(item => item.chain.chainId !== payload)
        state.unread.totalCount = state.unread.totalCount - 1
      }
    },
    addOrReplaceMessage: (state, {payload}: PayloadAction<NotificationType>) => {
      const isFoundInRead = !!state.read.items.find(item => item.chain.chainId === payload.chain.chainId)
      if (isFoundInRead){
        state.read.items.filter(item => item.chain.chainId !== payload.chain.chainId)
        state.read.totalCount = state.read.totalCount - 1
      }
      const isFoundInUnread = !!state.unread.items.find(item => item.chain.chainId === payload.chain.chainId)
      if (isFoundInUnread){
        state.unread.items = [
          payload,
          ...state.unread.items.filter(item => item.chain.chainId !== payload.chain.chainId),
        ]
      } else {
        state.unread.items = [
          payload,
          ...state.unread.items
        ]
        state.unread.totalCount = state.unread.totalCount + 1
      }
    },
    startDownload: (state, action) => {
      if (!state.downloadingReports.includes(action.payload)) {
        state.downloadingReports.push(action.payload)
      }
    },
    endDownload: (state, action) => {
      console.log(444,state)
      state.downloadingReports = state.downloadingReports.filter(id => id !== action.payload)
    }
  }
})

export default notificationsSlice.reducer

export const {
  setUnreadNotifications,
  setReadNotifications,
  readUnreadNotification,
  addOrReplaceMessage,
  startDownload,
  endDownload
} = notificationsSlice.actions